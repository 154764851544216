/* eslint-disable @next/next/no-html-link-for-pages */
import Image from "next/image";
import logo from "./assets/images/larneuk-logo.png";
import harratonLogo from "./assets/images/harraton-logo.png";
import eurellLogo from "./assets/images/logo-eurell.jpeg";
import middlebrookLogo from "./assets/images/middlebrook.png";

import { SectionTitle } from "./common/SectionTitle";
// @ts-ignore
import Pulse from "react-reveal/Pulse";

export default function Partners() {
  return (
    <section>
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-20 sm:px-6 lg:px-8">
        <SectionTitle isCentered>Our Partners</SectionTitle>
        <Pulse>
          <div className="flow-root my-32">
            <div className="grid-cols-2 gap-4 md:grid">
              <div className="flex justify-center h-48">
                <a
                  href="https://www.larneuk.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={logo}
                    alt="Larneuk Farm"
                    width={230}
                    height={97}
                    placeholder="blur"
                  />
                </a>
              </div>
              <div className="flex justify-center h-48">
                <a
                  href="https://www.harratoncourtstables.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={harratonLogo}
                    alt="Harraton Court Stables"
                    width={230}
                    height={97}
                    placeholder="blur"
                  />
                </a>
              </div>
              <div className="flex justify-center h-48">
                <a
                  href="http://gregeurell.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={eurellLogo}
                    alt="Greg Eurell Thoroughbred Training Centre"
                    width={287}
                    height={97}
                    placeholder="blur"
                  />
                </a>
              </div>
              <div className="flex justify-center h-48">
                <a
                  href="https://www.middlebrookvalleylodge.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={middlebrookLogo}
                    alt="Middlebrook Valley Lodge"
                    width={355}
                    height={50}
                    placeholder="blur"
                  />
                </a>
              </div>
            </div>
          </div>
        </Pulse>
      </div>
    </section>
  );
}
