import React from "react";
import { DialogContext, DialogName } from "../../context/DialogContext";
import Image from "next/image";
import communityImage from "../assets/images/community.jpeg";
// @ts-ignore
import Pulse from "react-reveal/Pulse";
import Partners from "../Partners";

export const ContactSection = () => {
  const { setCurrentDialog } = React.useContext(DialogContext);

  return (
    <>
      <div className="relative py-16 ">
        <div
          className="absolute inset-x-0 top-0 hidden h-1/2 lg:block"
          aria-hidden="true"
        />
        <div className="mx-auto bg-gray-900 max-w-7xl lg:bg-transparent lg:px-8">
          <div className="lg:grid lg:grid-cols-12">
            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
              <div
                className="absolute inset-x-0 h-1/2 lg:hidden"
                aria-hidden="true"
              />
              <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                <Image
                  className="object-cover object-center shadow-2xl rounded-3xl"
                  src={communityImage}
                  alt="Maxima"
                  height={320}
                  width={320}
                />
              </div>
            </div>

            <div className="relative bg-gray-900 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
              <div
                className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform bottom-full left-full translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-green-900"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                  />
                </svg>
                <svg
                  className="absolute transform top-full -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-green-900"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                  />
                </svg>
              </div>

              <Pulse>
                <div className="relative max-w-md px-4 py-12 mx-auto space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                  <h2
                    className="text-2xl font-extrabold text-center text-white md:text-4xl lg:text-left"
                    id="join-heading"
                  >
                    Subscribe and Join the community
                  </h2>
                  <p className="text-xl text-center text-white lg:text-left">
                    You will be in touch! Join the waiting list and be the first
                    to reserve your horses! Early stage partnership and business
                    contact are also welcome.
                  </p>
                  <button
                    className="block w-full px-5 py-3 text-base font-medium text-center border rounded-md shadow-md cursor-pointer text-maxima-secondary-400 hover:border-green-300 hover:text-green-300 border-maxima-secondary-400 sm:inline-block sm:w-auto"
                    onClick={() => setCurrentDialog(DialogName.Contact)}
                  >
                    One click subscribe
                  </button>
                </div>
              </Pulse>
            </div>
          </div>
        </div>
      </div>
      <Partners />
    </>
  );
};
