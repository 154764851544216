import { SectionTitle } from "./common/SectionTitle";
// @ts-ignore
import Fade from "react-reveal/Fade";
import { ContactSection } from "./contact/ContactSection";
import { activeYear } from "../constants";

const roadmaps = [
  {
    quartar: "NOW",
    items: [
      "Legal and professional advice",
      "Smart Contract Audit and KYC",
      "Business model experiment with suppliers",
    ],
  },
  {
    quartar: activeYear + " Q4",
    items: [
      "Marketplace NFT ticket launch",
      "$MAX Token Pre-Sale and IDO",
      "Cross-chain support on Layer-2",
    ],
  },
  {
    quartar: activeYear + 1 + " Q1",
    items: [
      "Marketplace public launch",
      "fNFTs and ticket redemption",
      "Personal NFT Dashboard",
    ],
  },
  {
    quartar: activeYear + 1 + " Q3",
    items: [
      "Maxima Metaverse Products",
      "Maxima Token payment & governance",
      "Gamified Horse activities",
    ],
  },
  {
    quartar: activeYear + 2,
    items: [
      "Marketplace Mobile App MVP",
      "Horse-racinng Livestream and IoT",
      "Maxima DAO Ecosystem",
    ],
  },
  {
    quartar: activeYear + 3,
    items: [
      "Maxima Social Platform",
      "Maxima DeFi Products",
      "NFT loans/rentals usecases",
    ],
  },
  {
    quartar: activeYear + 4,
    items: [
      "Horse Breeding and Nurturing Plans",
      "Maxima Stud Book on Blockchain",
      "Ecosystem Integration: Extended Reality",
    ],
  },
];

export default function Roadmap() {
  const dot = (
    <div className="z-20 flex items-center hidden w-4 h-4 rounded-full shadow-xl bg-maxima-secondary-400 md:block" />
  );

  return (
    <section className="pt-2 lg:pt-12 anchor-roadmap">
      <SectionTitle isCentered>Roadmap</SectionTitle>

      <div className="container w-full h-full p-4 mx-auto lg:mt-16 md:w-10/12 xl:w-8/12">
        <div className="relative h-full overflow-hidden wrap">
          <div className="absolute hidden h-full border border-maximar-secondary-400 md:block border-2-2 border-opacity-20 left-1/2"></div>
          {roadmaps.map((roadmap, index) => {
            const direction = index % 2;
            return (
              <Fade bottom key={roadmap.quartar}>
                <div
                  className={`items-center justify-between w-full mb-8 md:flex ${
                    direction === 1 ? "md:flex-row-reverse" : ""
                  }`}
                >
                  <div className="md:w-5/12"></div>
                  {dot}
                  <div className="rounded-lg shadow-xl md:w-5/12">
                    <h3 className="mb-3 text-2xl font-bold text-gray-200">
                      {roadmap.quartar}
                    </h3>

                    <ul className="text-lg leading-loose tracking-wide text-gray-400 text-opacity-100 list-disc list-inside">
                      {roadmap.items.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </div>

      <div>
        <ContactSection />
      </div>
    </section>
  );
}
